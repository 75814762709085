export default function Placeholder()
{

    const placeholderStyle = {
        fontFamily: 'mrsSheppards',
        color: 'white',
        position: 'absolute',
        zIndex: -1,
        fontSize: '50px',
        top: '10%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: 0,
        margin: 0,
        width: '100%',
        textAlign: 'center'
    }

    return <>
        <div style={placeholderStyle}>coming soon..</div>
    </>
}