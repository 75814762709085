import Experience from "./Experience"
import Placeholder from "./Placeholder/Placeholder"
import LogoFile from "./SostLogo/SostLogo"

export default function App()
{
    return <>
        <Experience />
        <Placeholder />
        <LogoFile />
    </>
}