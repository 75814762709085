import { Canvas } from '@react-three/fiber'
import { OrbitControls, Text3D, Center, Stage, Environment } from '@react-three/drei'
import Geezify from 'geezify-js'

export default function Experience()
{
    const geez = Geezify.create()
    const sost = geez.toGeez(3)
    console.log(sost)

    return(
        <> 
            <Canvas camera={{position: [0, 0, 2]}}>
                <OrbitControls 
                    maxAzimuthAngle={Math.PI * 0.2}
                    minAzimuthAngle={-Math.PI * 0.2}
                    minPolarAngle={Math.PI * 0.4}
                    maxPolarAngle={Math.PI * 0.6}
                    enableZoom = {false}
                    enablePan = {false}
                />
                    <Environment files='./EnvMaps/symmetrical_garden.hdr' />
                    <Center>
                        <Text3D font='./Fonts/notoEthiopic.json' >
                            {sost}
                            {/* <meshNormalMaterial /> */}
                            <meshStandardMaterial  metalness={0.6} roughness={0.5}/>
                        </Text3D>
                        <ambientLight />
                    </Center> 
            </Canvas>
        </>
    )
}