

const LogoFile = () => {
    const textStyle = {
        position: 'absolute',
        zIndex: '-1',
        left: '40%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        fontFamily: 'mrsSheppards',
        color: '#2f2f2f',
        fontSize: '50vw',
        textAlign: 'center',
        // padding: '20px'
    }
    return(
        <>
            <div style={textStyle}>sost</div>
        </>
    )
    }
    
    export default LogoFile